<template>
  <div class="pt-5 text-center">
    <img class="" src="@/assets/svg/404.svg" alt="webmag logo"/>
    <h1>{{ $t('pageNotFound') }}</h1>
    <router-link to="/">Go to dashboard</router-link>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style scoped>
  img{
    max-width:200px;
  }
</style>
