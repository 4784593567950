var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pt-5 text-center" },
    [
      _c("img", {
        attrs: { src: require("@/assets/svg/404.svg"), alt: "webmag logo" }
      }),
      _c("h1", [_vm._v(_vm._s(_vm.$t("pageNotFound")))]),
      _c("router-link", { attrs: { to: "/" } }, [_vm._v("Go to dashboard")])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }